@import "../node_modules/normalize.css/normalize.css";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600;700&display=swap");
@import "./DesignSystem/";
@import "./common";
@import "./header";
@import "./footer";
@import "./home";
@import "./about";
@import "./modal";
@import "./slider";
@import "./testimonial";
@import "./testimonialSection";
